import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const PdfHandler = ({ fileName: initialFileName }) => {
  const { category } = useParams()
  /**
   * If a filename is not provided, we will use the category to determine the filename.
   * If a filename is provided, we will use that filename.
   */

  useEffect(() => {
    let fileName = initialFileName

    if (!fileName) {
      switch (category) {
        case 'electronics':
          fileName = 'Policy_Wording_Wakam_Frankly_ADT_EW_012025.pdf'
          break

        default:
          window.location.href = '/conditions'
          return
      }
    }

    window.location.href = `/pdf/${fileName}`
  }, [initialFileName, category])

  return null
}

export default PdfHandler
